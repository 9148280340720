<template>
  <mcb-detail class="fio" @esc="cancelHandle" @failure="failureHandle" @success="successHandle" :readOnly="readOnly">
    <template v-slot:photo>
      <div class="photo">
        <img v-if="photo" :src="'data:image/png;base64, ' + photo" />
      </div>
    </template>

    <template v-slot:default>
      <el-form label-width="110px" label-position="left">
        <el-form-item label="Фамилия" prop="last">
          <el-autocomplete v-model="last" :fetch-suggestions="searchLastName" placeholder="Фамилия" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Имя" prop="first">
          <el-autocomplete v-model="first" :fetch-suggestions="searchFirstName" placeholder="Имя" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Отчество" prop="patronymic">
          <el-autocomplete v-model="patronymic" :fetch-suggestions="searchPatronymicName" placeholder="Отчество" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Причина" prop="reason" v-if="reason && readOnly">
          <el-input type="textarea" :rows="4" v-model="reason" :readOnly="readOnly" />
        </el-form-item>
      </el-form>
    </template>
  </mcb-detail>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'
import { PRESCRIPTION_RECOGNIZED_FIO, PRESCRIPTION_RECOGNIZED } from '@/components/utils.js'

export default {
  mixins: [DetailPage],

  data () {
    return {
      photo: '',
      first: '',
      last: '',
      patronymic: '',
      reason: ''
    }
  },

  beforeMount () {
    this.loadFioData()
  },

  computed: {
    readOnly () {
      return this.mutableValue.status == PRESCRIPTION_RECOGNIZED_FIO || this.mutableValue.status >= PRESCRIPTION_RECOGNIZED
    }
  },

  methods: {
    loadFioData () {
      this.$wait.show()
      this.$axios.post('/prescription/' + this.mutableValue.id + '/fio')
        .then(({data}) => {
          this.last = data.last || ''
          this.first = data.first || ''
          this.patronymic = data.patronymic || ''
          this.reason = data.reason || ''
          this.photo = data.photo
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
        .finally(() => {
          this.$wait.hide()
        })
    },

    trimName (name) {
      return ( name || '' ).replace( /^\s+|\s+$/g, '' )
    },

    searchName (t, name, cb) {
      name = this.trimName(name)
      if (name) {
        this.$axios.get('completion/' + t + '?name=' + name)
          .then(response => {
            cb(response.data)
          })
          .catch(err => {
            console.log(err)
            cb([])
          })
      } else {
        cb([])
      }
    },

    searchLastName (name, cb) {
      this.searchName('last', name, cb)
    },

    searchFirstName (name, cb) {
      this.searchName('first', name, cb)
    },

    searchPatronymicName (name, cb) {
      this.searchName('patronymic', name, cb)
    },

    failureHandle (val) {
      const url = '/prescription/' + this.mutableValue.id + '/failure'
      this.sendData(url, { reason: val })
    },

    successHandle () {
      const fio = {
        first: this.trimName(this.first),
        last: this.trimName(this.last),
        patronymic: this.trimName(this.patronymic),
      }

      if (fio.first || fio.last || fio.patronymic) {
        const url = '/prescription/' + this.mutableValue.id + '/fio/success'
        this.sendData(url, fio)
      }
    },

    sendData (url, data) {
      this.$axios.post(url, data)
        .then(({data}) => {
          this.$set(this.mutableValue, 'status', data.status)
          this.$emit('input', this.mutableValue)
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
    }
  }
}
</script>

<style scoped lang="less" >
@import "../style.less";

.fio {
  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .el-autocomplete {
    width: 100%;
  }
}
</style>
